import { Application } from "@hotwired/stimulus";

import Swiper_controller from "./controllers/swiper_controller.js";
import Accordion_controller from "./controllers/ui/accordion_controller.js";
import Dropdown_controller from "./controllers/ui/dropdown_controller.js";
import Dropdownv2_controller from "./controllers/ui/dropdownv2_controller.js";
import Mobile_menu_controller from "./controllers/mobile_menu_controller.js";
import Nouislider_controller from "./controllers/nouislider_controller.js";

const application = Application.start();

application.register("swiper", Swiper_controller);
application.register("ui--accordion", Accordion_controller);
application.register("ui--popover", Dropdown_controller);
application.register("ui--dropdownv2", Dropdownv2_controller);
application.register("mobile-menu", Mobile_menu_controller);
application.register("nouislider", Nouislider_controller);

console.log("Staticv2 loaded with selected controllers");

// Lazy Load Images when the page is loaded or navigated with Turbo
function initializeLazyLoad() {
    document.removeEventListener('DOMContentLoaded', initializeLazyLoad)

    // Select all images with the data-lazy attribute
    const lazyImages = document.querySelectorAll('img[data-lazy]');

    // IntersectionObserver options
    const options = {
        root: null, // Use the viewport as the root
        rootMargin: '0px 0px 100px 0px', // Load images just before they enter the viewport
        threshold: 0.1 // Trigger when 10% of the image is visible
    };

    // IntersectionObserver callback function
    function onIntersection(entries, observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const img = entry.target;
                const imgSrc = img.getAttribute('data-src');

                // Set the actual image source
                img.setAttribute('src', imgSrc);

                // Remove data-lazy attribute after loading the image
                img.removeAttribute('data-lazy');
                img.removeAttribute('data-src');

                // Stop observing this image once it's loaded
                observer.unobserve(img);
            }
        });
    }

    // Create the IntersectionObserver
    const observer = new IntersectionObserver(onIntersection, options);

    // Observe each lazy image
    lazyImages.forEach(image => {
        observer.observe(image);
    });
}

// Run the lazy load script on the document load event
document.addEventListener('DOMContentLoaded', initializeLazyLoad);
document.addEventListener('turbo:render', initializeLazyLoad)

console.log("Lazy loading initialized.");
